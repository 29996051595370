import React from "react";
import Logo from "./Logo";
import Menu from "./Menu";



function NavBar(){
    return <div className="navbar">
            <Logo></Logo>
            <Menu></Menu>
    </div>;
}


export default NavBar;