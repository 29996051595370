import Web3 from "web3";

export function ContractLoader(library, contractName, address = null){
    var data = require(`./${contractName}`);    
    const web3 = new Web3(library.provider);
    if(address != null){
        return {
            contract: (new web3.eth.Contract(data.abi, address)), 
            address: address
        }   
    }
    return {
        contract: (new web3.eth.Contract(data.abi, data.address)), 
        address: data.address
    };
}